import { hasDisplayLabelProperty, hasNameProperty, QriDetailsV2 } from '@qualio/search-qri';
import { QualityEventDetailsResponse } from '../types';

const extractRelatedQrisFromQueryEventData = (eventData?: QualityEventDetailsResponse) => {
  if (!eventData) {
    return [];
  }
  const suppliers = eventData.issue.suppliers ?? [];
  const relatedDocuments = eventData.issue.related_documents ?? [];
  const relatedEvents = eventData.issue.related_events ?? [];
  const relatedProduct = eventData.issue.related_product ? [eventData.issue.related_product] : [];
  const relatedRootCause = eventData.issue.related_rootcause ? [eventData.issue.related_rootcause] : [];
  return [...suppliers, ...relatedDocuments, ...relatedEvents, ...relatedProduct, ...relatedRootCause];
};

const qriDetailsAsSelectData = (
  data: QriDetailsV2[],
): {
  value: string;
  label: string;
}[] => {
  return data
    .map((item) => {
      let label, value;
      if (hasNameProperty(item)) {
        label = item.name;
        value = item.identifiers.qri;
        return { value, label };
      }
      if (hasDisplayLabelProperty(item)) {
        label = item.displayLabel;
        value = item.identifiers.qri;
        return { value, label };
      }
      return undefined;
    })
    .filter((val): val is { value: string; label: string } => val !== undefined);
};

export { extractRelatedQrisFromQueryEventData, qriDetailsAsSelectData };
