import React, { useCallback, useEffect, useMemo } from 'react';
import {
  QBox,
  QStack,
  QHeading,
  QText,
  QCloseButton,
  QSpacer,
  QDivider,
  QCenter,
  QButton,
  CurrentUserContextType,
  useToastProvider,
  useCurrentUser,
} from '@qualio/ui-components';
import { TemplateDetailsForm, TemplateFieldsList, TemplateStepsList } from '../../../components';
import { EventTemplateFormSchema, EventTemplateFormValues, User } from '../../../types';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTemplateMutation } from '../hooks';
import StepInput from './StepInput';
import EventTemplateHeader from './EventTemplateHeader';
import StepRenameModal from '../../../components/TemplateStepsList/components/StepRenameModal';
import ValidationStepTypeModal from '../../../components/TemplateStepsList/components/ValidationStepTypeModal';
import { StepInputState } from '../../../types/eventTemplateStepInputState';
import * as DisplayStrings from '../../../displayStrings';
import { ArrayElement } from '../../../utils/typeUtils';
import { getBlankStep } from '../../../utils/eventTemplateUtils';
import { elementAtIndex } from '../../../utils/arrayUtils';
import { useFlags } from '../../../external/LaunchDarklyAdapter';
import MakeEffectiveModal from '../../../components/MakeEffectiveModal/MakeEffectiveModal';
import { useQueryClient } from 'react-query';
import { BASE_QUERY_KEY } from '../../QualityEventTemplates/hooks';
import DeleteDraftEventTemplateConfirmationModal from '../../../components/DeleteDraftEventTemplateConfirmationModal/DeleteDraftEventTemplateConfirmationModal';
import ChangeEventTemplateOwnerModal from '../../../components/ChangeEventTemplateOwnerModal/ChangeEventTemplateOwnerModal';

type EventTemplateFormProps = {
  initialValues: EventTemplateFormValues;
  possibleOwners: readonly User[];
  companyId: CurrentUserContextType['companyId'];
  templateId?: number;
  defaultOwnerHasPermission?: boolean | null;
  ownerId?: number | null;
  ownerName?: string | null;
};

const EventTemplateForm = (props: EventTemplateFormProps) => {
  const { initialValues, companyId, templateId, possibleOwners, defaultOwnerHasPermission, ownerId, ownerName } = props;
  const isCreateView = !templateId;
  const [stepInputState, setStepInputState] = React.useState<StepInputState>({ type: 'empty' });
  const [isMakeEffectiveModalOpen, setIsMakeEffectiveModalOpen] = React.useState<boolean>(false);
  const [isDeleteDraftModalOpen, setIsDeleteDraftModalOpen] = React.useState<boolean>(false);
  const [isChangeOwnerModalOpen, setIsChangeOwnerModalOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { showToast } = useToastProvider();
  const { userId } = useCurrentUser();
  const queryClient = useQueryClient();
  const qeTemplateChangeManagement = useFlags('qeTemplateChangeManagement');
  const isEventTemplateEditable = useMemo(
    () =>
      !qeTemplateChangeManagement ||
      (qeTemplateChangeManagement && ownerId === userId && initialValues.status === 'draft'),
    [initialValues.status, ownerId, qeTemplateChangeManagement, userId],
  );

  const formMethods = useForm<EventTemplateFormValues>({
    mode: 'onSubmit',
    resolver: zodResolver(EventTemplateFormSchema),
    defaultValues: initialValues,
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { isDirty, isValid, dirtyFields },
    reset,
    trigger,
  } = formMethods;

  const mutationArgs = isCreateView
    ? ({ mode: 'create', companyId } as const)
    : ({ mode: 'update', companyId, templateId, originalTemplate: initialValues } as const);
  const { mutate: mutateTemplate, isLoading: templateIsMutating } = useTemplateMutation(mutationArgs);

  const onSubmit = useCallback(
    (payload: EventTemplateFormValues) => {
      mutateTemplate(payload, {
        onSuccess: (data) => reset(data),
        onError: (e) => {
          setIsMakeEffectiveModalOpen(false);
          showToast({
            title: DisplayStrings.EventTemplateUpdateFailTitle,
            description: e.message,
            status: 'error',
          });
        },
      });
    },
    [mutateTemplate, reset, showToast],
  );

  useEffect(() => {
    if (templateId && defaultOwnerHasPermission !== null && !defaultOwnerHasPermission) {
      showToast({
        title: qeTemplateChangeManagement
          ? DisplayStrings.DefaultAssigneeNoPermissionTitle
          : DisplayStrings.DefaultOwnerNoPermissionTitle,
        description: qeTemplateChangeManagement
          ? DisplayStrings.DefaultAssigneeNoPermissionMessage
          : DisplayStrings.DefaultOwnerNoPermissionMessage,
        status: 'warning',
      });
    }
    if (qeTemplateChangeManagement && (dirtyFields.steps || dirtyFields.fields)) {
      // Save immediately if anything in fields and steps are changed
      handleSubmit(onSubmit)();
    } else if (qeTemplateChangeManagement && isDirty) {
      // if not just ignore
      reset(undefined, { keepValues: true });
    }
  }, [
    templateId,
    defaultOwnerHasPermission,
    showToast,
    isDirty,
    handleSubmit,
    onSubmit,
    dirtyFields,
    reset,
    qeTemplateChangeManagement,
  ]);

  const setTemplateFields = useCallback(
    (fields: EventTemplateFormValues['fields']) => {
      setValue('fields', fields, { shouldDirty: true });
      trigger('fields');
    },
    [setValue, trigger],
  );

  const setTemplateSteps = useCallback(
    (steps: EventTemplateFormValues['steps']) => {
      const processedSteps = steps.map((step, index) => {
        return {
          ...step,
          order: index + 1,
        };
      });

      setValue('steps', processedSteps, { shouldDirty: true });
      trigger('steps');
    },
    [setValue, trigger],
  );

  const setStepToRename = useCallback(
    (stepIndex: number) => {
      const steps = getValues('steps');
      const step = elementAtIndex(steps, stepIndex);

      setStepInputState({ type: 'rename', step, stepIndex });
    },
    [setStepInputState, getValues],
  );

  const setValidationTypeChange = useCallback(
    (stepIndex: number) => {
      const steps = getValues('steps');
      const step = elementAtIndex(steps, stepIndex);

      setStepInputState({ type: 'validation-type-change', step, stepIndex });
    },
    [setStepInputState, getValues],
  );

  const closeStepInput = useCallback(() => setStepInputState({ type: 'empty' }), [setStepInputState]);

  const saveAndShowSignOffModal = useCallback(() => {
    setIsMakeEffectiveModalOpen(true);
    handleSubmit(onSubmit)();
  }, [handleSubmit, onSubmit]);

  const saveAndNavigateToTemplateListPage = useCallback(() => {
    handleSubmit(onSubmit)();
    queryClient.invalidateQueries([BASE_QUERY_KEY, companyId]).then(() => navigate('/templates'));
  }, [companyId, handleSubmit, navigate, onSubmit, queryClient]);

  return (
    <>
      <QBox data-cy="event-template-screen">
        {qeTemplateChangeManagement && templateId ? (
          <EventTemplateHeader
            templateId={templateId}
            isMakeEffectiveBtnDisabled={!isValid}
            makeEffectiveBtnAction={saveAndShowSignOffModal}
            saveAndExitBtnAction={saveAndNavigateToTemplateListPage}
            deleteDraftBtnAction={() => setIsDeleteDraftModalOpen(true)}
            changeOwnerBtnAction={() => setIsChangeOwnerModalOpen(true)}
          />
        ) : (
          <QStack direction="row">
            <QBox>
              <QHeading size="lg">{isCreateView ? 'Create' : 'Edit'} event template</QHeading>
              {!isCreateView && <QText color="gray.500">{getValues('name')}</QText>}
            </QBox>
            <QSpacer />
            <QCloseButton onClick={() => navigate('/templates')} />
          </QStack>
        )}
        <QDivider mt={6} mb={6} />
        <QCenter>
          <QStack direction="column" spacing={8} width="100%">
            <QHeading size="md">Event properties</QHeading>
            <TemplateDetailsForm
              formMethods={formMethods}
              isCreateView={isCreateView}
              possibleOwners={possibleOwners}
              isTemplateReadonly={!isEventTemplateEditable}
            />
            <QDivider />
            <TemplateFieldsList
              templateFields={getValues('fields')}
              setTemplateFields={setTemplateFields}
              isTemplateReadonly={!isEventTemplateEditable}
            />
            <TemplateStepsList
              companyId={companyId}
              templateSteps={getValues('steps')}
              setTemplateSteps={setTemplateSteps}
              disableEdit={isDirty || isCreateView}
              isTemplateReadonly={!isEventTemplateEditable}
              renameTemplateStep={setStepToRename}
              changeValidationStepType={setValidationTypeChange}
            />
            {stepInputState.type === 'empty' && isEventTemplateEditable ? (
              <QBox>
                <QButton
                  data-cy="add-template-step"
                  variant="outline"
                  leftIcon="Plus"
                  isDisabled={!isEventTemplateEditable}
                  onClick={() => setStepInputState({ type: 'create' })}
                >
                  {DisplayStrings.AddStep}
                </QButton>
              </QBox>
            ) : null}
            {stepInputState.type === 'create' ? (
              <StepInput
                title={DisplayStrings.AddStep}
                submitText={DisplayStrings.AddStep}
                submitLabel="add-step-confirm"
                submit={(stepName, stepType) => {
                  const steps = getValues('steps').slice();
                  const newStep = {
                    ...getBlankStep(),
                    label: stepName,
                    type: stepType,
                  } as ArrayElement<EventTemplateFormValues['steps']>;
                  const validationStep = steps.pop();
                  const newStepArray = [...steps, newStep];

                  if (validationStep) {
                    newStepArray.push(validationStep);
                  }

                  setTemplateSteps(newStepArray);
                  closeStepInput();
                }}
                checkDuplicated={(stepName) => {
                  const stepNames = new Set(getValues('steps').map((step) => step.label));

                  return stepNames.has(stepName.trim());
                }}
                closeInput={closeStepInput}
              />
            ) : null}
          </QStack>
        </QCenter>
        <QDivider mt={6} mb={6} />
        {qeTemplateChangeManagement ? (
          <QStack spacing={4} direction="row-reverse">
            {initialValues.status === 'draft' && ownerId === userId && (
              <>
                <QButton data-cy="make-effective-button-bottom" isDisabled={!isValid} onClick={saveAndShowSignOffModal}>
                  {DisplayStrings.MakeEffective}
                </QButton>
                <QButton
                  data-cy="save-and-exit-button-bottom"
                  onClick={saveAndNavigateToTemplateListPage}
                  variant="outline"
                >
                  {DisplayStrings.SaveAndExit}
                </QButton>
              </>
            )}
          </QStack>
        ) : (
          <QStack spacing={4} direction="row-reverse">
            <QButton
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isDirty || !isValid}
              data-cy="EventTemplateSaveButton"
              isLoading={templateIsMutating}
            >
              {DisplayStrings.SaveChanges}
            </QButton>
            <QButton variant="link" onClick={() => navigate('/templates')} data-cy="cancelButton">
              {DisplayStrings.Cancel}
            </QButton>
          </QStack>
        )}
      </QBox>
      {stepInputState.type === 'rename' ? (
        <StepRenameModal
          stepInputState={stepInputState}
          closeInput={() => setStepInputState({ type: 'empty' })}
          currentName={stepInputState.step.label}
          submit={(stepName) => {
            const steps = getValues('steps').slice();
            const step = elementAtIndex(steps, stepInputState.stepIndex);
            step.label = stepName;

            setTemplateSteps(steps);
            setStepInputState({ type: 'empty' });
          }}
          checkDuplicated={(stepName) => {
            const stepNames = new Set(
              getValues('steps')
                .filter((step) => step.label !== stepInputState.step.label)
                .map((step) => step.label),
            );

            return stepNames.has(stepName.trim());
          }}
        />
      ) : null}
      {stepInputState.type === 'validation-type-change' ? (
        <ValidationStepTypeModal
          stepInputState={stepInputState}
          currentType={stepInputState.step.type}
          closeInput={() => setStepInputState({ type: 'empty' })}
          submit={(stepType: 'document' | 'form') => {
            const steps = getValues('steps').slice();
            const step = elementAtIndex(steps, stepInputState.stepIndex);
            step.type = stepType;
            setTemplateSteps(steps);
            setStepInputState({ type: 'empty' });
          }}
        />
      ) : null}
      {templateId && (
        <MakeEffectiveModal
          isOpen={isMakeEffectiveModalOpen}
          setIsOpen={setIsMakeEffectiveModalOpen}
          templateId={templateId}
          lineageId={initialValues.lineage_id}
        />
      )}
      {qeTemplateChangeManagement && templateId && (
        <DeleteDraftEventTemplateConfirmationModal
          isOpen={isDeleteDraftModalOpen}
          setIsOpen={setIsDeleteDraftModalOpen}
          lineageId={initialValues.lineage_id}
        />
      )}
      {qeTemplateChangeManagement && templateId && ownerId && ownerName && (
        <ChangeEventTemplateOwnerModal
          isOpen={isChangeOwnerModalOpen}
          setIsOpen={setIsChangeOwnerModalOpen}
          templateId={templateId}
          lineageId={initialValues.lineage_id}
          currentOwnerName={ownerName}
          currentOwnerId={String(ownerId)}
        />
      )}
    </>
  );
};

export default EventTemplateForm;
