import {
  EventTemplateCreateEdit,
  EventTemplateCreateEditBase,
  EventTemplateDetailsResponse,
  EventTemplateStepCreateEdit,
} from '../types';
import { EventTemplateField } from '../types/eventField';
import { elementAtIndex } from '../utils/arrayUtils';
import { medtechApi } from './index';
import { CurrentUserContextType } from '@qualio/ui-components';
import { DigitalSignature } from '../types/digitalSignature';
import { UpdateDraftEventTemplate } from '../types/updateDraftEventTemplate';

const templatesApi = {
  async getEventTemplateDetails(
    companyId: CurrentUserContextType['companyId'],
    templateId: number,
  ): Promise<EventTemplateDetailsResponse> {
    const response = await medtechApi.get(`/v2/${companyId}/workflows/${templateId}`);
    return EventTemplateDetailsResponse.parse(response.data);
  },

  async createTemplate(
    companyId: CurrentUserContextType['companyId'],
    payload: EventTemplateCreateEdit,
    templateFields: EventTemplateField[],
    templateSteps: EventTemplateStepCreateEdit[],
  ): Promise<EventTemplateDetailsResponse> {
    payload.fields = [...templateFields];
    payload.steps = [...templateSteps];

    if (payload.steps.length) {
      payload.steps[payload.steps.length - 1]!.start_time_constraint = payload.start_time_constraint;
    }
    const response = await medtechApi.post(`/v2/${companyId}/workflows`, { workflow: payload });
    return EventTemplateDetailsResponse.parse(response.data);
  },

  async createTemplateWithoutFieldsAndSteps(
    companyId: number,
    payload: EventTemplateCreateEditBase,
  ): Promise<EventTemplateDetailsResponse> {
    const response = await medtechApi.post(`/v2/${companyId}/workflows`, { workflow: payload });
    return EventTemplateDetailsResponse.parse(response.data);
  },

  async updateTemplate(
    companyId: CurrentUserContextType['companyId'],
    templateId: string,
    payload: EventTemplateCreateEdit,
    templateFields: EventTemplateField[],
    templateSteps: EventTemplateStepCreateEdit[],
  ): Promise<EventTemplateDetailsResponse> {
    const finalPayload = {
      ...payload,
      fields: [...templateFields],
      steps: [...templateSteps],
    };

    elementAtIndex(finalPayload.steps, finalPayload.steps.length - 1).start_time_constraint =
      payload.start_time_constraint;

    const response = await medtechApi.post(`/v2/${companyId}/workflows/${templateId}`, { workflow: finalPayload });

    return EventTemplateDetailsResponse.parse(response.data);
  },

  async createNewDraftTemplate(
    companyId: CurrentUserContextType['companyId'],
    lineageId: string,
  ): Promise<EventTemplateDetailsResponse> {
    const response = await medtechApi.post(`/v2/${companyId}/workflows/${lineageId}/draft`);
    return EventTemplateDetailsResponse.parse(response.data);
  },

  async makeTemplateEffective(
    companyId: CurrentUserContextType['companyId'],
    lineageId: string,
    payload: DigitalSignature,
  ): Promise<void> {
    await medtechApi.post(`/${companyId}/workflows/${lineageId}/effective`, payload);
  },

  async archiveTemplate(companyId: CurrentUserContextType['companyId'], templateId: string): Promise<string> {
    await medtechApi.post(`/v2/${companyId}/workflows/${templateId}/archive`);
    return templateId;
  },

  async restoreTemplate(companyId: CurrentUserContextType['companyId'], templateId: string): Promise<string> {
    await medtechApi.post(`/v2/${companyId}/workflows/${templateId}/restore`);
    return templateId;
  },

  async deleteDraftTemplate(companyId: CurrentUserContextType['companyId'], lineageId: string): Promise<void> {
    await medtechApi.delete(`/v2/${companyId}/workflows/${lineageId}/draft`);
  },

  async patchDraftTemplate(
    companyId: CurrentUserContextType['companyId'],
    lineageId: string,
    payload: UpdateDraftEventTemplate,
  ): Promise<void> {
    await medtechApi.patch(`/v2/${companyId}/workflows/${lineageId}/draft`, payload);
  },
};

export default templatesApi;
