import React from 'react';
import { QFormControl, QInput, QStack, QTextarea } from '@qualio/ui-components';
import { Controller, UseFormReturn } from 'react-hook-form';
import { DigitalSignatureV2 } from '../../../types/digitalSignature';

type Props = {
  control: UseFormReturn<DigitalSignatureV2>['control'];
  isCommentRequired: boolean;
  emailPlaceholder?: string;
  passwordPlaceholder?: string;
  commentLabel?: string;
  commentPlaceholder?: string;
};

const DigitalSignatureForm: React.FC<Props> = ({
  control,
  isCommentRequired,
  emailPlaceholder,
  passwordPlaceholder,
  commentLabel,
  commentPlaceholder,
}) => {
  return (
    <QStack direction="column" spacing={4}>
      <Controller
        control={control}
        name="email"
        render={({ field, fieldState }) => {
          return (
            <QFormControl
              label="Email"
              error={fieldState.error?.message}
              isInvalid={Boolean(fieldState.error)}
              isRequired
            >
              <QInput {...field} placeholder={emailPlaceholder ? emailPlaceholder : ''} />
            </QFormControl>
          );
        }}
      />
      <Controller
        control={control}
        name="password"
        render={({ field, fieldState }) => {
          return (
            <QFormControl
              label="Password"
              error={fieldState.error?.message}
              isInvalid={Boolean(fieldState.error)}
              isRequired
            >
              <QInput
                role="textbox"
                type="password"
                {...field}
                placeholder={passwordPlaceholder ? passwordPlaceholder : ''}
              />
            </QFormControl>
          );
        }}
      />
      <Controller
        control={control}
        name="comment"
        render={({ field, fieldState }) => {
          const { ref: _ref, ...fieldProps } = field;

          return (
            <QFormControl
              label={commentLabel ? commentLabel : 'Comment'}
              error={fieldState.error?.message}
              isInvalid={Boolean(fieldState.error)}
              isRequired={isCommentRequired}
            >
              <QTextarea
                data-cy="comment-input"
                {...fieldProps}
                placeholder={commentPlaceholder ? commentPlaceholder : ''}
              />
            </QFormControl>
          );
        }}
      />
    </QStack>
  );
};

export default DigitalSignatureForm;
