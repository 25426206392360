import React, { useCallback, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  QButton,
  QCloseButton,
  QFlex,
  QFormControl,
  QInput,
  QLink,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QSelect,
  QStack,
  QText,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';

import {
  EventTemplateCreateEditBase,
  EventTemplateCreateEditBaseSchema,
  EventTemplateDetailsResponse,
  User,
} from '../../types';
import { filterByPermission } from '../../utils/userUtils';
import {
  DefaultAssignee,
  DefaultAssigneeHelper,
  DefaultTimeLimitHelpText,
  EventTemplateCreatedFailTitle,
  PrefixHelpText,
  ValidationStepTimeDelayHelpText,
} from '../../displayStrings';
import { useMutation } from 'react-query';
import templatesApi from '../../api/templates.api';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { extractMessageFromError } from '../../utils/errorUtils';
import * as DisplayStrings from '../../displayStrings';

export const defaultFormValues = {
  name: '',
  prefix: '',
  default_time_limit: 28,
  start_time_constraint: 0,
} satisfies EventTemplateCreateEditBase;

type CreateEditEventTemplateModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  allUsers: User[];
};

const CreateEditEventTemplateModal: React.FC<CreateEditEventTemplateModalProps> = ({ isOpen, setIsOpen, allUsers }) => {
  const defaultOwnerOptions = useMemo(
    () =>
      filterByPermission(allUsers, 'can_work_on_issue').map((owner) => ({
        value: owner.id,
        label: owner.full_name,
      })),
    [allUsers],
  );

  const formMethods = useForm<EventTemplateCreateEditBase>({
    mode: 'onSubmit',
    resolver: zodResolver(EventTemplateCreateEditBaseSchema),
    defaultValues: defaultFormValues,
  });

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = formMethods;

  const formHasErrors = !!Object.keys(errors).length;

  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
  };

  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();

  const { mutate: createEventTemplateMutate } = useMutation(
    (payload: EventTemplateCreateEditBase) => templatesApi.createTemplateWithoutFieldsAndSteps(companyId, payload),
    {
      onSuccess: (response: EventTemplateDetailsResponse) => {
        navigate(`/templates/${response.id}`);
        showToast({
          title: DisplayStrings.EventTemplateCreated,
          description: `${response.name} was created`,
          status: 'success',
        });
      },
      onError: (error: AxiosError) => {
        const message = extractMessageFromError(error);
        if (message.includes('Duplicated prefix')) {
          setError('prefix', {
            type: 'server',
            message: message,
          });
        }
        showToast({
          title: EventTemplateCreatedFailTitle,
          description: message,
          status: 'error',
        });
      },
    },
  );

  const onSubmit = useCallback(
    async (payload: EventTemplateCreateEditBase) => {
      createEventTemplateMutate(payload);
    },
    [createEventTemplateMutate],
  );

  return (
    <QModal isOpen={isOpen} onClose={handleClose} size="xl">
      <QModalHeader>
        <QText>Create event template</QText>
        <QCloseButton onClick={handleClose} data-metrics="create-event-template-close-modal-button" />
      </QModalHeader>
      <QModalBody>
        <QStack direction="column" color="gray.700" spacing={4}>
          <FormProvider {...formMethods}>
            <form>
              <QStack direction="column" spacing={4}>
                <QFormControl
                  label="Title"
                  error={errors.name && errors.name.message}
                  isInvalid={!!errors.name}
                  isRequired
                  data-metrics="event-template-title-input"
                >
                  <Controller
                    control={control}
                    name="name"
                    render={({ field: { ref: _ref, ...field } }) => <QInput {...field} />}
                  />
                </QFormControl>
                <QFormControl
                  label="Prefix"
                  helper={PrefixHelpText}
                  error={errors.prefix && errors.prefix.message}
                  isInvalid={!!errors.prefix}
                  isRequired
                  data-metrics="event-template-prefix-input"
                >
                  <Controller
                    control={control}
                    name="prefix"
                    render={({ field: { ref: _ref, ...field } }) => <QInput {...field} />}
                  />
                </QFormControl>
                <QFormControl
                  label={DefaultAssignee}
                  helper={
                    <QText>
                      {DefaultAssigneeHelper}
                      <QLink
                        isExternal
                        href="https://docs.qualio.com/en/articles/10040334-create-workflows-with-integrations"
                      >
                        Learn more
                      </QLink>
                    </QText>
                  }
                  error={errors.default_owner_id && errors.default_owner_id.message}
                  isInvalid={!!errors.default_owner_id}
                  data-metrics="event-template-default-event-owner-input"
                >
                  <Controller
                    control={control}
                    name="default_owner_id"
                    render={({ field: { ref: _ref, onChange, ...field } }) => (
                      <QSelect
                        {...field}
                        onChange={(changeObject) => {
                          if (changeObject) {
                            onChange(changeObject.value);
                          }
                        }}
                        options={defaultOwnerOptions}
                        menuPosition="fixed"
                      />
                    )}
                  />
                </QFormControl>
                <QFormControl
                  label="Default time limit"
                  helper={DefaultTimeLimitHelpText}
                  error={errors.default_time_limit && errors.default_time_limit.message}
                  isInvalid={!!errors.default_time_limit}
                  isRequired
                  data-metrics="event-template-default-time-limit-input"
                >
                  <Controller
                    control={control}
                    name="default_time_limit"
                    render={({ field: { ref: _ref, ...field } }) => (
                      <QFlex direction="row" width="25%">
                        <QInput {...field} />
                        <QText ml={2} alignContent="center">
                          days
                        </QText>
                      </QFlex>
                    )}
                  />
                </QFormControl>
                <QFormControl
                  label="Validation step time delay"
                  helper={ValidationStepTimeDelayHelpText}
                  error={errors.start_time_constraint && errors.start_time_constraint.message}
                  isInvalid={!!errors.start_time_constraint}
                  isRequired
                  data-metrics="event-template-validation-step-time-delay-input"
                >
                  <Controller
                    control={control}
                    name="start_time_constraint"
                    render={({ field: { ref: _ref, ...field } }) => (
                      <QFlex direction="row" width="25%">
                        <QInput {...field} />
                        <QText ml={2} alignContent="center">
                          days
                        </QText>
                      </QFlex>
                    )}
                  />
                </QFormControl>
              </QStack>
            </form>
          </FormProvider>
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton onClick={handleClose} variant="outline" data-metrics="create-event-template-cancel-button">
          Cancel
        </QButton>
        <QButton
          onClick={handleSubmit(onSubmit)}
          isDisabled={formHasErrors}
          data-metrics="create-event-template-button"
        >
          Create template
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default CreateEditEventTemplateModal;
