import React, { useState } from 'react';
import {
  QModal,
  QStack,
  QText,
  useToastProvider,
  QModalHeader,
  QModalBody,
  QModalActions,
  QButton,
  QCloseButton,
  QFormControl,
  QBox,
  QSelect,
  useCurrentUser,
} from '@qualio/ui-components';
import {
  ChangeEventTemplateOwnerSuccessMessage,
  ChangeTemplateOwner,
  CurrentEventTemplateOwnerLabel,
  Failed,
  Save,
  Success,
} from '../../displayStrings';
import { useUsersOptions } from '../../hooks/useUsersOptions';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import usersApi from '../../api/users.api';
import templatesApi from '../../api/templates.api';
import { baseEventTemplateQueryKey } from '../../hooks';
import { BASE_QUERY_KEY } from '../../views/QualityEventTemplates/hooks';
import { AxiosError } from 'axios';
import { extractMessageFromError } from '../../utils/errorUtils';

type ChangeEventTemplateOwnerModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  lineageId: string;
  templateId: number;
  currentOwnerName: string;
  currentOwnerId: string;
};

const ChangeEventTemplateOwnerModal: React.FC<ChangeEventTemplateOwnerModalProps> = ({
  isOpen,
  setIsOpen,
  lineageId,
  templateId,
  currentOwnerName,
  currentOwnerId,
}) => {
  const { companyId } = useCurrentUser();
  const [ownerToChange, setOwnerToChange] = useState(currentOwnerId);

  const { data: usersData } = useQuery(['usersList', companyId], () =>
    usersApi.getAllUsers(companyId, { status: 'accepted' }),
  );

  const usersOptions = useUsersOptions(usersData);

  const { showToast } = useToastProvider();

  const handleClose = () => {
    setIsOpen(false);
  };

  const queryClient = useQueryClient();

  const { mutate: updateEventTemplateOwnerIdMutate } = useMutation(
    () => templatesApi.patchDraftTemplate(companyId, lineageId, { owner_id: Number(ownerToChange) }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([baseEventTemplateQueryKey, companyId, String(templateId)]);
        await queryClient.invalidateQueries([baseEventTemplateQueryKey, companyId, templateId]);
        await queryClient.invalidateQueries([BASE_QUERY_KEY, companyId]);
        setIsOpen(false);
        showToast({
          title: Success,
          description: ChangeEventTemplateOwnerSuccessMessage,
          status: 'success',
        });
      },
      onError: (error: AxiosError) => {
        const message = extractMessageFromError(error);
        showToast({
          title: Failed,
          description: message,
          status: 'error',
        });
      },
    },
  );

  return (
    <QModal data-cy={'change-owner-modal'} isOpen={isOpen} onClose={handleClose} size="xl">
      <QModalHeader>
        <QText>{ChangeTemplateOwner}</QText>
        <QCloseButton onClick={handleClose}></QCloseButton>
      </QModalHeader>
      <QModalBody>
        <QStack direction="column" color="gray.700" spacing={4}>
          <QFormControl label={CurrentEventTemplateOwnerLabel}>
            <QText>{currentOwnerName}</QText>
          </QFormControl>
          <QFormControl label="New template owner">
            <QBox data-cy="change-owner-select">
              <QSelect
                onChange={(changeObject) => {
                  if (changeObject) {
                    setOwnerToChange(changeObject.value);
                  }
                }}
                options={usersOptions}
                value={ownerToChange}
              />
            </QBox>
          </QFormControl>
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton onClick={handleClose} variant="outline">
          Cancel
        </QButton>
        <QButton
          data-cy="save-change-event-template-owner-button"
          onClick={() => updateEventTemplateOwnerIdMutate()}
          isDisabled={ownerToChange === currentOwnerId}
        >
          {Save}
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default ChangeEventTemplateOwnerModal;
