import { qriApi } from './index';
import { QriDetailsBulkV2, QriDetailsBulkV2Parser } from '@qualio/search-qri';

const qriServiceApi = {
  async getBulkDetails(qris: string[]): Promise<QriDetailsBulkV2 | undefined> {
    if (!qris.length) {
      return undefined;
    }
    const response = await qriApi.post('/details', {
      qris,
      short: false,
    });
    const result = QriDetailsBulkV2Parser.safeParse(response.data);

    if (result.success) {
      return result.data;
    } else {
      console.log('QRI bulk details response parsing error', result.error);
    }
  },
};

export default qriServiceApi;
