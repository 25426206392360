import React, { useCallback, useMemo, useState } from 'react';
import {
  useCurrentUser,
  QBox,
  QHeading,
  QButtonGroup,
  QButton,
  QSpinner,
  QStack,
  QSelect,
  QSelectItem,
  QFormControl,
  QDataTable,
  DataProvider,
  Pagination,
  QDivider,
} from '@qualio/ui-components';
import { EventType, EventTemplateStatus } from '../../types';
import { useNavigate } from 'react-router-dom';
import TemplateRowActions from './TemplateRowActions';
import { useColumnConfig, useGetTemplates } from './hooks';
import { useDocTitle } from '../../hooks';
import { useFlags } from '../../external/LaunchDarklyAdapter';
import * as DisplayStrings from '../../displayStrings';
import { useQuery } from 'react-query';
import usersApi from '../../api/users.api';
import CreateEditEventTemplateModal from '../../components/CreateEditEventTemplateModal/CreateEditEventTemplateModal';
import MakeEffectiveModal from '../../components/MakeEffectiveModal/MakeEffectiveModal';
import DeleteDraftEventTemplateConfirmationModal from '../../components/DeleteDraftEventTemplateConfirmationModal/DeleteDraftEventTemplateConfirmationModal';
import ChangeEventTemplateOwnerModal from '../../components/ChangeEventTemplateOwnerModal/ChangeEventTemplateOwnerModal';

type TemplateStatusValues = 'active' | 'archived' | 'all';

type VersionedTemplateStatusValues =
  | 'allActive'
  | Extract<EventTemplateStatus, 'draft' | 'effective' | 'superseded' | 'archived'>;

const AllActiveStatuses: EventTemplateStatus[] = ['draft', 'effective', 'for_approval', 'approval_declined'];

type TemplateStatusLabels = typeof DisplayStrings.TemplateEffective | typeof DisplayStrings.TemplateArchived | 'All';
type VersionedTemplateStatusLabels =
  | typeof DisplayStrings.TemplateAllActiveStatuses
  | typeof DisplayStrings.TemplateDraft
  | typeof DisplayStrings.TemplateEffective
  | typeof DisplayStrings.TemplateSuperseded
  | typeof DisplayStrings.TemplateArchived;

type StatusFilterOption = QSelectItem<TemplateStatusLabels, TemplateStatusValues>;
type VersionedStatusFilterOption = QSelectItem<VersionedTemplateStatusLabels, VersionedTemplateStatusValues>;
type SelectedTemplate = {
  templateId: number;
  lineageId: string;
  ownerName?: string;
  ownerId?: string;
};

const statusSelectOptions: ReadonlyArray<StatusFilterOption> = [
  { value: 'active', label: DisplayStrings.TemplateEffective },
  { value: 'archived', label: DisplayStrings.TemplateArchived },
  { value: 'all', label: 'All' },
] as const;

const versionedStatusSelectOptions: ReadonlyArray<VersionedStatusFilterOption> = [
  { value: 'allActive', label: DisplayStrings.TemplateAllActiveStatuses },
  { value: 'draft', label: DisplayStrings.TemplateDraft },
  { value: 'effective', label: DisplayStrings.TemplateEffective },
  { value: 'superseded', label: DisplayStrings.TemplateSuperseded },
  { value: 'archived', label: DisplayStrings.TemplateArchived },
] as const;

const statusFilterFunctions = {
  active: (template: EventType) => template.active,
  archived: (template: EventType) => !template.active,
  all: (_template: EventType) => true,
} as const;

const versionedStatusFilterFunctions = {
  allActive: (template: EventType) => AllActiveStatuses.includes(template.status) && template.active,
  draft: (template: EventType) => template.status === 'draft' && template.active,
  effective: (template: EventType) => template.status === 'effective' && template.active,
  superseded: (template: EventType) => template.status === 'superseded' && template.active,
  archived: (template: EventType) => !template.active,
} as const;

const QualityEventTemplates = () => {
  const { companyId } = useCurrentUser();
  const qualioFrontendRefreshEnabled = useFlags('qualioFrontendRefresh');
  const qeDefaultOwnersEnabled = useFlags('qeDefaultOwners');
  const qeTemplateChangeManagement = useFlags('qeTemplateChangeManagement');

  const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState<boolean>(false);
  const [isMakeEffectiveModalOpen, setIsMakeEffectiveModalOpen] = useState<boolean>(false);
  const [isChangeOwnerModalOpen, setIsChangeOwnerModalOpen] = useState<boolean>(false);
  const [isDeleteDraftModalOpen, setIsDeleteDraftModalOpen] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<SelectedTemplate>();

  const navigate = useNavigate();
  const { data: templates, isLoading } = useGetTemplates(companyId);
  const [statusFilter, setStatusFilter] = useState<TemplateStatusValues>('active');
  const [versionedStatusFilter, setVersionedStatusFilter] = useState<VersionedTemplateStatusValues>('allActive');

  const tableData = useMemo(() => {
    if (!templates) {
      return [];
    }

    const filterOnStatus = qeTemplateChangeManagement
      ? versionedStatusFilterFunctions[versionedStatusFilter]
      : statusFilterFunctions[statusFilter];

    return templates.filter(filterOnStatus);
  }, [templates, qeTemplateChangeManagement, versionedStatusFilter, statusFilter]);

  useDocTitle('Event templates - Qualio');

  const { columnHelper, columnConfig } = useColumnConfig(
    qualioFrontendRefreshEnabled,
    qeDefaultOwnersEnabled,
    qeTemplateChangeManagement,
  );

  const { data: allUsers, isLoading: isAllUserLoading } = useQuery(['userList', companyId], () =>
    usersApi.getAllUsers(companyId, { status: 'accepted' }),
  );

  const setTemplateForMakeEffective = useCallback((templateId: number, lineageId: string) => {
    setSelectedTemplate({ templateId, lineageId });
    setIsMakeEffectiveModalOpen(true);
  }, []);

  const setTemplateForChangeOwner = useCallback(
    (templateId: number, lineageId: string, ownerName: string, ownerId: string) => {
      setSelectedTemplate({ templateId, lineageId, ownerName, ownerId });
      setIsChangeOwnerModalOpen(true);
    },
    [],
  );

  const setTemplateForDeleteDraft = useCallback((templateId: number, lineageId: string) => {
    setSelectedTemplate({ templateId, lineageId });
    setIsDeleteDraftModalOpen(true);
  }, []);

  return (
    <>
      <QStack direction="column" spacing={6}>
        <QBox display="flex" flexDirection="row" justifyContent="space-between">
          <QHeading size="lg">Event templates</QHeading>
          <QButtonGroup>
            {qeTemplateChangeManagement ? (
              <QButton data-cy={'create-template'} variant="solid" onClick={() => setIsCreateEventModalOpen(true)}>
                Create event template
              </QButton>
            ) : (
              <QButton data-cy={'create-template'} variant="solid" onClick={() => navigate('/templates/new')}>
                Create template
              </QButton>
            )}
          </QButtonGroup>
        </QBox>
        {qeTemplateChangeManagement && <QDivider />}
        <QFormControl data-cy={'event-templates-filter'} w="300px">
          {qeTemplateChangeManagement ? (
            <QSelect
              value={versionedStatusFilter}
              options={versionedStatusSelectOptions}
              onChange={(changeItem) => changeItem && setVersionedStatusFilter(changeItem.value)}
            />
          ) : (
            <QSelect
              value={statusFilter}
              options={statusSelectOptions}
              onChange={(changeItem) => changeItem && setStatusFilter(changeItem.value)}
            />
          )}
        </QFormControl>
        {isLoading || (qeTemplateChangeManagement && isAllUserLoading) ? (
          <QBox w="100%" textAlign="center" p={5} mt={16}>
            <QSpinner />
          </QBox>
        ) : (
          <QBox data-cy={'event-templates-table'}>
            <Pagination.Auto itemCount={tableData.length} clientSide>
              <DataProvider.Fixed data={tableData}>
                <QDataTable
                  enableSorting
                  columns={columnConfig.concat([
                    columnHelper.menu({
                      hide: (template: EventType) => template.status === 'superseded',
                      items: (
                        <TemplateRowActions
                          setTemplateForMakeEffective={setTemplateForMakeEffective}
                          setTemplateForChangeOwner={setTemplateForChangeOwner}
                          setTemplateForDeleteDraft={setTemplateForDeleteDraft}
                          companyId={companyId}
                        />
                      ),
                    }),
                  ])}
                />
              </DataProvider.Fixed>
            </Pagination.Auto>
          </QBox>
        )}
      </QStack>
      {qeTemplateChangeManagement && allUsers && (
        <CreateEditEventTemplateModal
          isOpen={isCreateEventModalOpen}
          setIsOpen={setIsCreateEventModalOpen}
          allUsers={allUsers}
        />
      )}
      {selectedTemplate && (
        <MakeEffectiveModal
          isOpen={isMakeEffectiveModalOpen}
          setIsOpen={setIsMakeEffectiveModalOpen}
          templateId={selectedTemplate.templateId}
          lineageId={selectedTemplate.lineageId}
        />
      )}
      {qeTemplateChangeManagement && selectedTemplate && (
        <>
          <DeleteDraftEventTemplateConfirmationModal
            isOpen={isDeleteDraftModalOpen}
            setIsOpen={setIsDeleteDraftModalOpen}
            lineageId={selectedTemplate.lineageId}
          />
          <ChangeEventTemplateOwnerModal
            isOpen={isChangeOwnerModalOpen}
            setIsOpen={setIsChangeOwnerModalOpen}
            templateId={selectedTemplate.templateId}
            lineageId={selectedTemplate.lineageId}
            currentOwnerName={selectedTemplate.ownerName!}
            currentOwnerId={String(selectedTemplate.ownerId!)}
          />
        </>
      )}
    </>
  );
};

export default QualityEventTemplates;
