import React from 'react';
import { QResourceLabel, SearchDomain, QText, QLink } from '@qualio/ui-components';
import { formResolvedQriDisplayText } from './QObjectField/selectedItemText';
import { ViewMode } from '../EventFormField.types';
import { QriDetailsV2 } from '@qualio/search-qri';
export const ResourceLabelThatTriggersReferenceDrawer = ({
  handleQResourceLabelClick,
  resourceDomain,
  qriDetails,
  mode,
}: {
  handleQResourceLabelClick: (qriDetails: QriDetailsV2) => void;
  resourceDomain: SearchDomain;
  qriDetails: QriDetailsV2;
  mode: ViewMode;
}) => {
  return (
    <QResourceLabel domain={resourceDomain}>
      {mode === 'preview' ? (
        <QText fontSize="sm">{formResolvedQriDisplayText(qriDetails)}</QText>
      ) : (
        <QLink role="button" onClick={() => handleQResourceLabelClick(qriDetails)}>
          {formResolvedQriDisplayText(qriDetails)}
        </QLink>
      )}
    </QResourceLabel>
  );
};
